.beehiiv-auth-container {
  .platform-icon {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 5px !important;
    img {
      width: 55px;
      height: 55px;
    }
  }
  .platform-title {
    width: 100%;
    height: 50px;
    text-align: center;
    p {
      width: 274px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 140%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 16px;
      color: #121b2e;
    }
  }
  .platform-body {
    margin-top: 24px;
    padding-top: 16px;
    padding-bottom: 16px;
    height: 100%;
    ol.platform-instructions {
      list-style-type: none;
      counter-reset: my-counter;
      padding: 0px;
      a {
        color: #2262ed;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        text-decoration: underline;
      }
      li {
        position: relative;
        margin-bottom: 18px;
        padding-left: 36px;

        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: #121b2e;
        padding-top: 2px;
        span {
          cursor: pointer;
          color: #2262ed;
          text-decoration: underline;

          img {
            width: 18px;
            height: 18px;
            margin-left: 2px;
            vertical-align: text-bottom;
          }
        }
      }

      li:before {
        content: counter(my-counter);
        counter-increment: my-counter;
        position: absolute;
        left: 0;
        top: 0;
        width: 24px;
        height: 24px;
        border-radius: 24px;
        background-color: #313544;
        text-align: center;
        color: #ffffff;
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        margin-right: 12px; /* added padding between circle and text */
        padding: 2px 7px;
      }
    }
    .video-instructions-link {
      color: #2262ed;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      text-decoration-line: underline;
      color: #2262ed;
      cursor: pointer;
    }
  }
  #video-container {
    display: none;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    margin-top: 8px;
  }
  #video-container.show {
    display: block;
  }

  #video-container video {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  #closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 24px;
    height: 24px;
    background-color: #dadbdd;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    opacity: 0.6;
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
    color: black;
  }
}
