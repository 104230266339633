.background {
  position: relative;
  //   background: #b2b2b2;
  width: 100vw;
  height: 100vh;
}

@media screen and (max-width: 480px) {
  .background {
    background: #fff !important;
  }
}
