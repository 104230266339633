@import "components/main";
@import "layout/main";
@import "pages/main";
@import "global";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300&family=Staatliches&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

* {
    margin: 0;
    pad: 0;
    box-sizing: border-box;
    font-family: "Inter", sans-serif;
}

:root {
    --accentcolor: #2262ed;
    --defaultcolor: #2262ed;
}

body {
    background-color: rgb(0, 0, 0) !important; /* Fallback color */
    background-color: rgba(0, 0, 0, 0.24) !important; /* Black w/ opacity */
    backdrop-filter: blur(4px);
}

$titlefont: "Staatliches", cursive;
$primaryfont: "Inter", sans-serif;

.primary-btn {
    width: 100%;
    height: 48px;
    background-color: var(--accentcolor);
    color: #fff;
    border-radius: 4px;
    border-style: none;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
}
.primary-btn:focus {
    outline: var(--accentcolor);
}
.secondary-btn {
    width: 100%;
    height: 48px;
    background-color: #fff;
    color: var(--accentcolor);
    border: 1px solid var(--accentcolor);
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
}
.secondary-btn:focus {
    outline: var(--accentcolor);
}
.page-background {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.white-screen {
    width: 360px !important;
    height: 608px !important;
    position: fixed;
    background-color: white;
    box-shadow: 0px 18px 36px -18px rgba(0, 0, 0, 0.3), 0px 30px 60px -12px rgba(50, 50, 93, 0.25);
    border-radius: 8px;
    padding: 0px 12px;
}
.heading-text {
    font-weight: 600;
    font-size: 18px;
    color: #121b2e;
    text-align: center;
    line-height: 140%;
}
.heading-text-h2-insta {
    font-weight: 600;
    font-size: 18px;
    color: #121b2e;
    text-align: center;
    line-height: 25px;
    margin: 16px auto;
    width: 312px;
}

/*screen navbar*/
.navbar {
    height: 56px;
    margin-bottom: -27px !important;
    padding: 0px 8px !important;
}
.nav-cross-icon {
    margin: 16px 0px;
}
.nav-icon svg {
    cursor: pointer;
    margin-left: 10px;
}
.nav-icon img {
    cursor: pointer;
    // min-height: 30px;
    // min-width: 30px;
    height: 24px;
    max-height: 24px;
    max-width: 24px;
}
.navbar-info-icon svg {
    position: absolute;
    float: right;
    top: 16px;
    right: 9px;
    margin-bottom: 16px;
}
.right-icon {
    position: absolute;
    float: right;
    top: 16px;
    right: 9px;
    margin-bottom: 16px;
}
.info-icon {
    margin-right: 24px;
}
.back_icon {
    margin-left: 0px !important;
}

.intros-footer-txt {
    font-size: 10px;
    font-weight: normal;
    color: rgba(41, 41, 41, 0.8);
}
.intros-footer-txt strong a {
    font-size: 10px;
    color: var(--accentcolor) !important;
    font-weight: 500;
    text-decoration: underline;
}
.svg-mobile-illustration #helix {
    stroke: var(--accentcolor);
}

//common for intro-info
.mobile-illustration {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.mobile-illustration svg {
    height: auto;
    width: auto;
}

.Icongroup {
    display: flex;
    align-items: flex-start;
    position: relative;
    margin-top: 8px !important;
}
.intro-icon {
    left: -10px;
    top: -4px;
    width: 24px;
    height: 24px;
    position: absolute;
}
.intro-icon #terms-icon {
    fill: var(--accentcolor);
}
.Icon-heading {
    margin-left: 1.2rem;
}
.Icon-heading h4 {
    font-weight: 600;
    font-size: 14px;
    color: #313544;
}
.Icon-heading p {
    font-weight: 400;
    font-size: 12px;
    color: #51515f;
}
.failed-description-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.5;
    font-family: "Inter", sans-serif;
    color: #292929;
}

//common for logoutpopup,previosconnections,statusconnected
.profile-box {
    overflow: hidden;
    margin-top: 13px;
    @-moz-document url-prefix() {
        html,
        .profile-box {
            overflow: hidden !important;
        }
    }
    .profile-pic {
        border-radius: 50%;
        cursor: pointer;
        // width: 70px;
        --size: 48px;
        height: var(--size);
        width: var(--size);
    }

    @-moz-document url-prefix() {
        html,
        .profile-info p {
            overflow: hidden !important;
            word-wrap: break-word;
        }
    }
    .profile-info p {
        font-size: 12.8px;
        font-weight: 500;
        margin-bottom: 2px;
        word-wrap: break-word;
        color: #121b2e;
    }
    .error-icon {
        display: flex;
        align-items: center;
        img {
            width: 20px;
            margin-left: -2px;
        }

        p {
            width: 500px !important;
            color: var(--accentcolor);
            font-size: 12px;
            font-weight: 500;
            margin-bottom: 0px;
            margin-top: 1px;
        }
        .expire-txt {
            color: #ad3737 !important;
        }
    }
    .completed-icon {
        width: 55px;
        margin-left: -4px;
    }
    .completed-icon #completed-icon-fill {
        fill: var(--accentcolor);
    }
    .action-icon {
        width: 35px;
        cursor: pointer;
    }
}
.profile-info {
    margin-left: 6px;
    width: 120%;
}

.main-div-insta-2 {
    height: 370px;
    overflow: scroll;
}
.main-div-insta-instruction {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
}
.main-div-insta-instruction-text {
    width: 85%;
}
.main-div-insta-instruction-text p {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: #313544;
}

/* Used as global styles, dont change while refactoring */
.platform-icon-background {
    height: 57px;
    width: 57px;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 0px 10px 29px 0px #29292917;
    z-index: 10;
}

.themed-icon {
    fill: var(--accentcolor);
}

// Common css
.header-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    a {
        text-decoration: none;
        color: rgba($color: #ffffff, $alpha: 0.6);
        &:hover {
            color: rgba(255, 255, 255, 1);
        }
    }
}
.header-link {
    display: flex;
    gap: 30px;
}
.bg-color {
    background: #1b1b1b;
    padding: 0.5rem 4.5rem;
}
a {
    font-family: $primaryfont;
    font-weight: normal;
    text-decoration: none;
    font-size: 13px;
    line-height: 100%;
    // color: rgba(255, 255, 255, 0.6);
    text-decoration: none;
}

.continue-describ {
    width: 39% !important;
}
.dark-btn {
    margin-top: 1.5rem;
    padding: 1.2rem 3rem;
    font-family: $titlefont;
    font-size: 15px;
    font-weight: normal;
    letter-spacing: 0.12em;
    color: #ffffff;
    background: #6965e7;
    border-radius: 8px;
    border-style: none;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}
.dark-btn:hover {
    background: #fff;
    color: #6965e7;
    font-weight: bold;
}
.footer-link {
    color: rgba(255, 255, 255, 0.4);
}
// home screen css
.dark-bg {
    width: 100vw;
    height: 100vh;
    background: linear-gradient(142.34deg, #1a1a1a 21.78%, #000000 93%);
    overflow-x: hidden;

    .content-box-home {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 1.5rem 3rem;
    }

    .content-box {
        width: 100%;
        height: 80vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        & > h1 {
            font-family: $primaryfont;
            font-size: 70px;
            font-weight: 700;
            text-align: center;
            letter-spacing: 2px;
            width: 70%;
            color: rgba(255, 255, 255, 0.95);
        }
        & > p {
            text-align: center;
            width: 40%;
            font-family: $primaryfont;
            font-size: 22px;
            font-weight: 300;
            line-height: 150%;
            color: rgba(255, 255, 255, 0.7);
        }
    }
    .footer-nav {
        a {
            text-decoration: none;
            color: rgba($color: #ffffff, $alpha: 0.4);
        }
        .link-mobile {
            display: none;
        }
    }
}

// screen css
.head-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & > h2 {
        font-family: $primaryfont;
        font-size: 18px;
        font-weight: 600;
        line-height: 15px;
        color: #292929;
    }
    & > img {
        width: 55px;
        margin-top: 0.5rem;
        margin-bottom: 1rem;
    }
    & > p {
        font-family: $primaryfont;
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
        color: #292929;
        margin-bottom: 6px;
    }
    & > span {
        font-family: $primaryfont;
        font-size: 12px;
        font-weight: 500;
        line-height: 1.5;
        color: rgba($color: #29292999, $alpha: 0.6);
        text-align: center;
    }
}
.input-group {
    display: flex;
    flex-direction: column;
    width: 100%;
    & > label {
        font-family: $primaryfont;
        font-size: 12px;
        font-weight: 500;
        line-height: 12px;
        color: rgba($color: #29292999, $alpha: 0.7);
        margin-bottom: 7.5px;
    }
    & > input {
        padding: 0.8rem;
        font-family: $primaryfont;
        font-size: 15px;
        font-weight: 500;
        line-height: 17.5px;
        color: #292929;
        background-color: #f1f1f1;
        outline: none;
        border-style: none;
        border-radius: 5px 5px 5px 5px !important;
        margin-bottom: 17.5px;
        &:focus {
            border: solid 1.25px #2262ed;
        }
    }
    .password-icon {
        position: absolute;
        right: 25px;
        top: 106px;
        cursor: pointer;

        svg {
            width: 20px;
        }
    }
}

.wantaccess-head {
    text-align: center;
    line-height: 1.4 !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    margin-top: 20px;
    span {
        color: #524fa2;
    }
}

.checkbox-div {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 30px;
}
.checkbox-item {
    display: flex;
    gap: 1rem;
    .checkbox {
        position: absolute;
        width: 18px !important;
        height: 18px !important;
        background-color: red;
    }
    .checkbox-text {
        margin-left: 30px;
        h3 {
            font-family: $primaryfont;
            font-size: 14px;
            font-weight: 600;
        }
        p {
            font-family: $primaryfont;
            font-size: 10px;
            font-weight: 400;
            line-height: 18px;
        }
    }
}
.access-btn {
    margin-top: 30px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
}

.footer-new {
    position: absolute;
    bottom: 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #848484;
    color: #fff;
    text-align: center;
    p {
        margin-bottom: 0px;
        padding-top: 6px;
        padding-bottom: 6px;
    }
}
.proceed-div {
    margin-top: 100px !important;
}
//@media css

@media (max-width: 430px) {
    .loader-div {
        width: 100vw;
        height: 100vh;
        & p {
            width: 100%;
        }
    }
    .header-nav {
        justify-content: center;
        .header-link {
            display: none;
        }
    }
    .content-box {
        height: 70vh !important;
        & h1 {
            width: 100% !important;
            font-size: 32px !important;
        }
        & p {
            font-size: 14px !important;
            width: 100% !important;
        }
    }
    .link-mobile {
        display: contents !important;
    }
    .mobile-footer-link {
        display: flex;
        flex-direction: column;
        text-align: center;
        .footer-mobile-hov:hover {
            color: #fff;
        }
        .copy-right-mobile {
            font-size: 11px;
        }
    }
    .desk-link {
        display: none;
    }
    .footer_div p {
        font-size: 10px;
    }
}

/***************** responsive design *********************************/
@media screen and (max-width: 480px) {
    .background {
        background: #fff !important;
    }
    .white-screen {
        width: 100vw !important;
        height: 100% !important;
        border-radius: 0px !important;
        box-shadow: none !important;
    }
    .mobile-illustration {
        width: 300px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin: 0 auto;
    }
    .heading-text {
        font-size: 18px !important;
    }
    .primary-btn {
        font-size: 14px;
    }
    .secondary-btn {
        font-size: 14px;
    }
    .intros-footer-txt {
        font-size: 12px !important;
    }
    .intros-footer-txt strong {
        font-size: 12px !important;
    }
    .Icon-heading h4 {
        font-size: 15px !important;
    }
    .Icon-heading p {
        font-size: 15px !important;
    }
}

::-webkit-scrollbar {
    width: 0;
    background: transparent;
}

.MuiCircularProgress-colorPrimary {
    color: var(--accentcolor) !important;
}
