.stausfaildicon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: -9rem !important;
  img {
    width: 110px;
    margin-top: 30px;
  }
  p {
    font-size: 14px;
    line-height: 21px;
  }
}

/***************** responsive design *********************************/
@media screen and (max-width: 480px) {
  .stausfaildicon p {
    font-size: 14px;
  }
}
