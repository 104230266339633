.navbar-container {
  min-height: 56px;
  max-height: 56px;
  // margin-top: 24px;
  // max-height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  // padding-left: 9px;
  // padding-right: 20px;
  // padding: 0px 8px;

  img:hover {
    cursor: pointer;
  }

  .navbar-left-icons {
    margin-left: -4px;
  } 

  // margin-bottom: -40px !important;

  .navbar-right-icons {
    margin-right: -4px;
  }

  img {
    height: 24px;
    width: 24px;
  }
}
