.linkedin-profile-auth-container {
  .platform-content {
    height: 100%;
    .platform-icon {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      margin-top: 5px !important;
      img {
        width: 55px;
        height: 55px;
      }
    }
    .platform-title {
      width: 100%;
      text-align: center;
      > p {
        margin: 0px;
        width: 274px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 140%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 16px;
        color: #121b2e;
      }
    }
    .platform-description {
      width: 100%;
      text-align: center;
      > p {
        margin: 0px;
        width: 288px;
        margin-left: auto;
        margin-right: auto;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        text-align: center;
        color: #313544;
        margin-top: 12px;
      }
    }
    .info-img-container {
      margin-top: 16px;
      > img {
      }
    }
    .input-container {
      margin-top: 32px;
      .profile-info-link {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        text-decoration-line: underline;
        color: #2262ed;
        cursor: pointer;
      }
    }
  }
}
