.icon-list-container {
  margin-top: 8px !important;
  display: flex;
  flex-direction: row;

  .icon-item {
    margin-bottom: 2px;
    position: relative;
    bottom: 4px;
  }
}

.list-text {
  margin-left: 1rem;

  h4 {
    font-weight: 600;
    font-size: 14px;
    color: #292929;
  }
  p {
    font-weight: 400;
    font-size: 12px;
    // color: #292929;
    color: #696969;
  }
}

.icon-item #terms-icon {
  fill: var(--accentcolor);
}
