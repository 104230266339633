/*loaer css*/
.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 00px;
  color: #292929;
}

.helpText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  color: #8a8894;
  margin-top: 16px;
  width: 80%;
}
.helpText u {
  cursor: pointer;
}
