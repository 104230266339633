.beehiiv-select-acc-container {
  .platform-title {
    width: 100%;
    text-align: center;
    > p {
      margin: 0px;
      width: 274px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 140%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 16px;
      color: #121b2e;
    }
  }
  .platform-description {
    width: 100%;
    text-align: center;
    > p {
      margin: 0px;
      width: 288px;
      margin-left: auto;
      margin-right: auto;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 140%;
      text-align: center;
      color: #313544;
      margin-top: 20px;
    }
  }
  .accs-list-container {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    overflow-y: auto;
    height: 100%;
    margin-bottom: 24px;
    margin-left: -24px;
    margin-right: -24px;

    .acc-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      > img {
        width: 48px;
        height: 48px;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 12px;
      }
      > p {
        margin: 0;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;

        color: #292929;
      }
    }
    .acc-container:hover {
      background-color: #f7f7fb;
    }
  }
}

.radio-btn-container {
  color: #b9b8c0 !important;
  padding: 0px !important;
  width: 20px;
  height: 20px;
}
.radio-btn-checked {
  color: #2262ed !important;
}

.radio-btn-container:hover {
  background: none !important;
}

.radio-btn-checked:hover {
  background: none !important;
}
.radio-btn-label {
  margin: 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px !important;
  line-height: 17px;
  display: flex;
  align-items: center;

  color: #292929;
}

.radio-btn-label-container {
  margin-left: 0px !important;
  padding-left: 24px;
  padding-right: 26px;
  margin-top: 28px;
  margin-bottom: 28px;
  height: 100%;
}
