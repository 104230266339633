.primary-button {
  margin-bottom: 24px;
  // width: 100%;
  min-height: 48px;
  background-color: var(--accentcolor);
  color: #fff;
  border-radius: 3px;
  border-style: none;
  font-weight: 500;
  font-size: 14px;
  // margin-inline: 12px;
}
.primary-button:focus {
  outline: var(--accentcolor);
}
