.labelled-input {
  display: flex;
  flex-direction: column;
  width: 100%;

  .error-message {
    color: #cc5a36;
    font-size: 10px;
    font-weight: 500;
  }

  & > label {
    // font-family: $primaryfont;
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    color: #757480;
    margin-bottom: 5px;
    margin-left: 4.02px;
  }
  .input-field {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .input-field-right-icon {
    position: absolute;
    right: 10px;
  }

  input {
    width: 100%;
    padding: 0.87rem;
    // font-family: $primaryfont;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.5px;
    color: #292929;
    background-color: #f1f1f1;
    outline: none;
    border: solid 1.5px white;
    border-radius: 4px !important;
    &:focus {
      border-color: var(--accentcolor);
      background-color: white;
      caret-color: var(--accentcolor);
    }
  }
  .password-icon {
    position: absolute;
    right: 25px;
    top: 106px;
    cursor: pointer;

    svg {
      width: 20px;
    }
  }
}
