.card-container {
  width: 360px;
  height: 608px;
  background-color: white;
  box-shadow: 0px 18px 36px -18px rgba(0, 0, 0, 0.3),
    0px 30px 60px -12px rgba(50, 50, 93, 0.25);
  border-radius: 8px;
  padding: 0px 24px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 480px) {
    width: 100%;
    height: 100%;
    border-radius: 0px !important;
  }
}
