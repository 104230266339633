.Icongroup {
    display: flex;
    align-items: flex-start;
    position: relative;
    margin-top: 8px !important;
}

.Icon-heading {
    margin-left: 1rem;
}
.intro-icon {
    left: -10px;
    top: -4px;
    width: 24px;
    height: 24px;
    position: absolute;
}
.intro-icon #terms-icon {
    fill: var(--accentcolor);
}
.Icon-heading h4 {
    font-weight: 600;
    font-size: 14px;
    color: #313544;
}
.Icon-heading p {
    font-weight: 400;
    font-size: 12px;
    color: #51515f;
}
.intros-info-div {
    margin-left: 5%;
}
.intros-footer-txt {
    font-size: 10px;
    font-weight: normal;
    color: #8a8894;
}
.intros-footer-txt strong a {
    font-size: 10px;
    color: var(--accentcolor) !important;
    font-weight: 500;
    text-decoration: underline;
}
.back_icon {
    margin-left: 0px !important;
}

.right-icon {
    position: absolute;
    float: right;
    top: 16px;
    right: 9px;
    margin-bottom: 16px;
}
.info-icon {
    margin-right: 24px;
}

.navbar {
    height: 56px;
    margin-bottom: -27px !important;
    padding: 0px 8px !important;
}

.nav-icon svg {
    cursor: pointer;
    margin-left: 10px;
}
.nav-icon img {
    cursor: pointer;
    // min-height: 30px;
    // min-width: 30px;
    height: 24px;
    max-height: 24px;
    max-width: 24px;
}

.mobile-illustration {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.mobile-illustration svg {
    height: auto;
    width: auto;
}

.default_logo_placeholder {
    position: absolute;
    left: 13%;
}

.client_logo_placeholder {
    height: 36px;
    width: 36px;
    position: absolute;
    left: 13.2%;
}

.svg-mobile-illustration #helix {
    stroke: var(--accentcolor);
}

.workplatform_logo_placeholder {
    height: 36px;
    width: 36px;
    position: absolute;
    right: 13.45%;
}

.user_logo_placeholder {
    position: absolute;
    right: 13.2%;
}

.heading-text {
    font-weight: 600;
    font-size: 18px;
    color: #121b2e;
    text-align: center;
    line-height: 140%;
}

.demo_div {
    height: 350px;
    margin-left: 5%;
    display: flex;
    flex-direction: column;
    padding-bottom: 8px;
}

.demo_div:before {
    z-index: 2;
    content: "";
    width: 315px;
    height: 50px;
    bottom: 20%;
    position: absolute;
    // background: -webkit-linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    // background: linear-gradient(to top, rgba(255, 255, 255, 80%) 0%, rgba(255, 255, 255, 0) 100%);
}

.instructions-text {
    flex-grow: 1;
    // overflow-y: auto;
}

@-moz-document url-prefix() {
    html,
    .demo_div {
        overflow: scroll !important;
        scrollbar-width: none;
    }
}

h4 {
    margin-bottom: 0.25rem;
}

@media screen and (max-width: 480px) {
    .mobile-illustration {
        width: 300px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin: 0 auto;
    }
    .heading-text {
        font-size: 18px !important;
    }
    .intros-footer-txt {
        font-size: 12px !important;
    }
    .intros-footer-txt strong {
        font-size: 12px !important;
    }
}
// .footer-intro-div {
//     position: fixed;
//     left: 0;
//     right: 0;
//     padding: 10px;
//     bottom: 0;
// }
.scrollable-content-info {
    // height: calc(100vh - 150px) !important;
    overflow-y: scroll;
    // margin-top: 15px;
}
@media screen and (max-height: 700px) {
    .scrollable-content-info {
        height: calc(100vh - 150px) !important;
        overflow: scroll;
        margin-top: 15px;
    }
    .white-screen {
        height: 90% !important;
    }
    .background {
        position: relative;
        width: 100vw;
        height: 100vh;
        border-radius: 8px;
    }
    .footer-intro-div {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        width: 360px;
        margin: auto !important;
        flex: 0 0 auto;
        max-width: 100%;
        display: inline-flex;
        padding: 16px 24px;
        flex-direction: column;
        align-items: center;
        gap: 14px;
        border-top: 1px solid var(--Neutral-Grey-11, #d4d3d9);
        background: var(--Neutral-Grey-15, #fff);
        // border-radius: 8px;
        .primary-btn {
            margin-bottom: 4px !important;
        }
    }
    .header-intro-div {
        z-index: 2;
        margin-bottom: -27px !important;
        padding: 0px 8px !important;
        position: absolute;
        left: 0;
        right: 0;
        top: -0;
        width: 312px;
        height: 42px;
        flex-shrink: 0;
        background: #fff;
        margin: auto;
    }
}
