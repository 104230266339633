.polling-short-loader-container {
  display: flex;
  height: 80%;
  width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.polling-short-loader-container > *:not(:first-child) {
  margin-top: 46px;
  font-weight: 600;
}

.animation-phyllo-connecting-platform {
  width: 100%;
}

.polling-long-loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 80%;

  .description-tiny {
    margin-top: 4.2px;
    font-weight: 400;
    color: rgba(41, 41, 41, 0.6);
    font-size: 12px;
    text-align: center;
  }

  .faded-title {
    color: rgba(41, 41, 41, 0.8);
    font-size: 14px;
  }
}

.animating-description-carousel {
  margin-top: 8px;
  width: 240px;
  height: 36px;
  text-align: center;
}

.phyllo-connecting-platform-animation {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.spiral-image {
  animation: reveal 1.5s infinite;
  // width: 150%;

  @keyframes reveal {
    from {
      clip-path: inset(0 100% 0 0);
    }
    to {
      clip-path: inset(0 0 0 0);
    }
  }

  // .banner-rectangle {
  //   height: 100%;
  //   background-color: red;
  //   // width: 100%;
  // }

  // @keyframes center-animate {
  //   0% {
  //     transform: scaleX(121);
  //   }
  //   100% {
  //     transform: scaleX(1);
  //   }
  // }
}

// .platform-connecting-title {
//   animation: 2s anim-lineUp ease-out;

//   @keyframes anim-lineUp {
//     0% {
//       opacity: 0;
//       transform: translateY(80%);
//     }
//     20% {
//       opacity: 0;
//     }
//     50% {
//       opacity: 1;
//       transform: translateY(0%);
//     }
//     100% {
//       opacity: 1;
//       transform: translateY(0%);
//     }
//   }
// }
.platform-icon-with-circular-background {
  &:first-child {
    // background-color: red;
    transform: translate(50%, 0px);
  }
  &:last-child {
    // background-color: yellow;
    transform: translate(-50%, 0px);
  }
  > img {
    width: 40px;
  }
}
