.main-div-insta {
    height: 235px;
    overflow: scroll;
}
.custom-info-icon-img {
    width: 48px;
    height: 48px;
}
.question-info-container-ios {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.question-info-container-ios ul {
    margin: 0;
    font-size: 14px !important;
}

@media screen and (max-width: 480px) {
    .primary-btn-ios {
        font-size: 14px;
    }
}

@media screen and (max-height: 700px) {
    .scrollable-content-info-insta {
        height: calc(100vh - 100px) !important;
        overflow: scroll;
        margin-top: 15px;
        padding-bottom: 150px;
    }
    .white-screen {
        height: 100% !important;
    }
    .footer-intro-div-ios {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        width: 360px;
        margin: auto !important;
        flex: 0 0 auto;
        max-width: 100%;
        display: inline-flex;
        padding: 16px 24px;
        flex-direction: column;
        align-items: center;
        gap: 14px;
        background: var(--Neutral-Grey-15, #fff);
    }
}
