.card-title {
  font-weight: 600;
  font-size: 18px;
  color: #292929;
  text-align: center;
  line-height: 1.5;
  font-family: "Inter", sans-serif;
  margin-top: 16px;

  @media screen and (max-width: 480px) {
    font-size: 18px !important;
  }
}
