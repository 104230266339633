.main-div-insta {
    height: 235px;
    overflow: scroll;
}
.main-div-insta p {
    font-size: 14px;
    font-weight: 500;
    color: #313544;
}
.main-div-insta-2 {
    height: 330px;
    overflow: scroll;
}
.main-div-insta-instruction {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
}
.main-div-insta-instruction-text {
    width: 85%;
}
.main-div-insta-instruction-text p {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: #313544;
}
.question-info-container {
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    margin-bottom: 1rem;
}
.question-info-container p {
    font-size: 14px !important;
    margin: 0;
}
.question-info-container svg {
    cursor: pointer;
}
.question-info-container #custom-info-icon {
    fill: var(--accentcolor);
}
.insta-auth-button-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.secondary-btn-insta-auth-enabled {
    width: 49%;
    height: 35px;
    background-color: #fff;
    color: var(--accentcolor);
    border: 2px solid var(--accentcolor);
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    cursor: pointer;
}
.secondary-btn-insta-auth-enabled:focus {
    outline: var(--accentcolor);
}
.secondary-btn-insta-auth-disabled {
    width: 49%;
    height: 35px;
    background-color: #fff;
    color: #8a8894;
    border: 1px solid #b9b8c0;
    border-radius: 4px;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    cursor: pointer;
}
.secondary-btn-insta-auth-disabled:focus {
    outline: grey;
}

/***************** responsive design *********************************/
@media screen and (max-width: 480px) {
}

@media screen and (max-height: 700px) {
    .scrollable-content-info-insta {
        height: calc(100vh - 100px) !important;
        overflow: scroll;
        margin-top: 15px;
        padding-bottom: 150px;
    }
    .white-screen {
        height: 100% !important;
    }
    .footer-intro-div {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        width: 360px;
        margin: auto !important;
        flex: 0 0 auto;
        max-width: 100%;
        display: inline-flex;
        padding: 16px 24px;
        flex-direction: column;
        align-items: center;
        gap: 14px;
        border-top: 1px solid var(--Neutral-Grey-11, #d4d3d9);
        background: var(--Neutral-Grey-15, #fff);
    }
    .header-intro-div {
        z-index: 2;
        margin-bottom: -27px !important;
        padding: 0px 8px !important;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        width: 312px;
        height: 56px;
        flex-shrink: 0;
        background: #fff;
        margin: auto;
    }
}

.primary-btn-img {
    width: 100%;
    height: 48px;
    color: #fff;
    border-radius: 4px;
    border-style: none;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
}